<template>
<div>

    <b-row class="match-height header_card_div">
        <b-col lg="12" md="12">
            <b-card>

                <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                    <div class="alert-body">
                        {{error_message}}
                    </div>
                </b-alert>
                
                <b-form @submit="formSubmit">

                    <div v-if="location">

                        <b-row>
                            <b-col md="12" >                        
                                <b-form-group label="" class="mt-1">
                                    <b-form-checkbox v-model="form.header_visibility" value="yes" unchecked-value="no" class="custom-control-success" switch >
                                        Header Visibility
                                    </b-form-checkbox>                                
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col md="12" >                        
                                <b-form-group label="">
                                    <b-form-checkbox v-model="form.hide_header_text" value="yes" unchecked-value="no" class="custom-control-primary" >
                                        Hide Header Text
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col md="6" >                        
                                <b-form-group label="Label Color" class="required">
                                    <input type="color" v-model="form.header_color" class="form-control">
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="12" >                        
                                <b-form-group label="Header Location" class="required">
                                    <b-form-radio v-model="form.header_location" value="top_left" class="ml-2 mb-1">Top Left</b-form-radio>
                                    <b-form-radio v-model="form.header_location" value="top_center" class="ml-2">Top Center</b-form-radio>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col class="mt-1">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="warning" class="mr-1" v-if="checkPermission($route.name, 'Update')">
                                    Update 
                                </b-button>
                            </b-col>
                        </b-row>

                    </div>
                    
                </b-form>

            </b-card>
        </b-col>
    </b-row>

</div>
</template>

<script>

import {
    BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BAlert,
    BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia,
    BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BFormTextarea, BBreadcrumb
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { GET_API, POST_API } from "../../../store/actions.type";
import vSelect from 'vue-select';

export default {
    components: {
        BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, 
        BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, 
        BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BFormTextarea, vSelect, BBreadcrumb
    },
    directives: {
        Ripple,
    },
    props: ['site', 'location'],
    data() {
        return {
            error_message        : null,
            showDismissibleAlert : false,

            header_location_options: ['Top Left', 'Top Center'],

            form: {
                site              : this.site,
                location          : this.location,
                header_visibility : 'yes',
                hide_header_text  : 'no',
                header_color      : '#ffffff',
                header_location   : 'top_left'
            }
        }
    },

    methods : {
        getDataHS(){
            return this.$store.dispatch(POST_API, {
                data:{
                    setting_name : 'header_settings',
                    location     : this.location
                    // site         : this.site
                },
                api: '/api/get-feedback-panel-setting-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    var data = this.$store.getters.getResults.data;

                    this.form.header_visibility = data.header_visibility;
                    this.form.hide_header_text  = data.hide_header_text;
                    this.form.header_color      = data.header_color == undefined ? '#ffffff' : data.header_color;
                    this.form.header_location   = data.header_location;
                }
            });
        },

        formSubmit(e){
            e.preventDefault();

            return this.$store.dispatch(POST_API, {
                data:{
                    site              : this.site,
                    location          : this.location,
                    header_visibility : this.form.header_visibility,
                    hide_header_text  : this.form.hide_header_text,
                    header_color      : this.form.header_color,
                    header_location   : this.form.header_location
                },
                api: '/api/insert-header-settings-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;

                    this.successAlert();                    
                }
            });        
        },

    },

    mounted(){
        this.getDataHS(); 
    },

    watch: {
        location: function(){
            this.getDataHS();
        }
    }
}
</script>


<template>
<div>

    <b-row class="match-height pin_code_card_body">
        <b-col lg="12" md="12">
            <b-card>

                <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                    <div class="alert-body">
                        {{error_message}}
                    </div>
                </b-alert>
                
                <b-form @submit="formSubmit">
                    <div v-if="location">

                        <b-row v-if="location != 'all-project-site-location'">
                            <b-col md="12" >                        
                                <b-form-group label="Current Pincode" class="required">
                                    <b-form-input type="number" v-model="form.old_pincode"/>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="12" >                        
                                <b-form-group label="New Pincode" class="required">
                                    <b-form-input type="number" v-model="form.new_pincode"/>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="12" >                        
                                <b-form-group label="Confirm Pincode" class="required">
                                    <b-form-input type="number" v-model="form.confirm_pincode"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col class="mt-1">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="warning" class="mr-1" v-if="checkPermission($route.name, 'Update')">
                                    Update 
                                </b-button>
                            </b-col>
                        </b-row>

                    </div>
                    
                </b-form>

            </b-card>
        </b-col>
    </b-row>

</div>
</template>

<script>

import {
    BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BAlert,
    BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia,
    BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BFormTextarea, BBreadcrumb
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { GET_API, POST_API } from "../../../store/actions.type";
import vSelect from 'vue-select';

export default {
    components: {
        BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, 
        BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, 
        BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BFormTextarea, vSelect, BBreadcrumb
    },
    directives: {
        Ripple,
    },
    props: ['site', 'location'],
    data() {
        return {
            error_message        : null,
            showDismissibleAlert : false,

            form: {
                site            : this.site,
                location        : this.location,
                old_pincode     : '',
                new_pincode     : '',
                confirm_pincode : ''
            }
        }
    },

    methods : {
        formSubmit(e){
            e.preventDefault();

            return this.$store.dispatch(POST_API, {
                data:{
                    site            : this.site,
                    location        : this.location,
                    old_pincode     : this.form.old_pincode,
                    new_pincode     : this.form.new_pincode,
                    confirm_pincode : this.form.confirm_pincode
                },
                api: '/api/change-pincode-web'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;

                    this.form.old_pincode  = '';
                    this.form.new_pincode  = '';
                    this.form.confirm_pincode  = '';

                    this.successAlert();
                }
            });        
        },

    },
}
</script>


<template>
<div>

    <b-row class="match-height pin_code_card_body">
        <b-col lg="12" md="12">
            <b-card>

                <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                    <div class="alert-body">
                        {{error_message}}
                    </div>
                </b-alert>
                
                <b-form @submit="formSubmit">

                    <div v-if="location">
                        
                        <b-row>
                            <b-col md="6" >                        
                                <b-form-group label="Do you want to play advertisement video ?" class="">
                                    <div class="demo-inline-spacing">
                                        <b-form-radio v-model="form.promotional_video" value="no" class="ml-2 mb-1" @change="resetData()">No</b-form-radio>
                                        <b-form-radio v-model="form.promotional_video" value="yes" class="ml-2 mb-1" @change="resetData()">Yes</b-form-radio>
                                    </div>

                                </b-form-group>
                            </b-col>
                            <b-col md="6" v-if="form.promotional_video == 'yes'">                        
                                <b-form-group label="Idle Time (in minutes)" class="required">
                                    <b-form-input v-model="form.idle_time"/>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row v-if="form.promotional_video == 'yes'">
                            <b-col md="6">                        
                                <b-form-group label="Advertisement video playing during" class="">
                                    <div class="demo-inline-spacing">
                                        <b-form-radio v-model="form.video_play" value="service_time" class="ml-2 mb-1">Service Time</b-form-radio>
                                        <b-form-radio v-model="form.video_play" value="off_time" class="ml-2 mb-1">OFF Time</b-form-radio>
                                        <b-form-radio v-model="form.video_play" value="both" class="ml-2 mb-1">Both</b-form-radio>
                                    </div>
                                </b-form-group>
                            </b-col>

                            <b-col md="6" >                        
                                <b-form-group label="Do you want to play sound ?" class="">
                                    <div class="demo-inline-spacing">
                                        <b-form-radio v-model="form.enable_sound" value="no" class="ml-2 mb-1">No</b-form-radio>
                                        <b-form-radio v-model="form.enable_sound" value="yes" class="ml-2 mb-1">Yes</b-form-radio>
                                    </div>

                                </b-form-group>
                            </b-col>

                            
                        </b-row>

                        <b-row v-if="form.promotional_video == 'yes'" class="mb-2">
                            <b-col md="12">
                                <b-form-group label="" class="mt-1">
                                    <b-form-checkbox v-model="form.show_advertisement_info" value="yes" unchecked-value="no" class="custom-control-success" switch @input="resetAdInfo();">
                                        Show Advertisement Info
                                    </b-form-checkbox>                                
                                </b-form-group> 
                            </b-col>
                        </b-row>

                        <b-row v-if="form.promotional_video == 'yes' && form.show_advertisement_info == 'yes'" class="mb-2">
                            <b-col md="6">
                                <b-form-group v-if="form.show_advertisement_info == 'yes'" label="Info Label" class="required">
                                    <b-form-input v-model="form.info_label"/>
                                </b-form-group>
                            </b-col>

                            <b-col md="6" v-if="form.show_advertisement_info == 'yes'">
                                <b-form-group v-if="form.show_advertisement_info == 'yes'" label="Label Position" class="required">
                                    <v-select                    
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        v-model="form.label_position"
                                        :options="label_position_list"
                                        placeholder="Select Label Position"
                                        :clearable="false"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <b-row v-if="form.promotional_video == 'yes' && form.show_advertisement_info == 'yes'" class="mb-2">
                            <b-col md="6">
                                <b-form-group v-if="form.show_advertisement_info == 'yes'" label="Info Label Color" class="required">
                                    <input type="color" v-model="form.label_text_color" class="form-control">
                                </b-form-group>
                            </b-col>

                            <b-col md="6" v-if="form.show_advertisement_info == 'yes'">
                                <b-form-group v-if="form.show_advertisement_info == 'yes'" label="Info Label Background Color" class="required">
                                    <input type="color" v-model="form.label_background_color" class="form-control">
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row v-if="form.promotional_video == 'yes'" class="mb-2">
                          <b-col md="6">

                            <b-form-group
                              label="Upload Advertisement Video"
                              class="mb-2 required">
                              
                              <b-form-file
                                id="extension"
                                ref="file-input"
                                
                                accept=".mp4"
                                @change="fileUpdate"
                              />
                            </b-form-group>

                            <p class="mt-2">
                              <video :src="form.video.image" style="width:200px" v-if="form.video.image != null" :autoplay=false loop :muted="form.enable_sound == 'yes' ? false : true" ></video>
                            </p>
                            

                          </b-col>
                        </b-row>

                        

                        <b-row>
                            <b-col class="mt-1">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="warning" class="mr-1" v-if="checkPermission($route.name, 'Update')">
                                    Update 
                                </b-button>
                            </b-col>
                        </b-row>

                    </div>
                    
                </b-form>

            </b-card>
        </b-col>
    </b-row>

</div>
</template>

<script>

import {
    BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BAlert,
    BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia,
    BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BFormTextarea, BBreadcrumb,BFormFile
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { GET_API, POST_API } from "../../../store/actions.type";
import vSelect from 'vue-select';

export default {
    components: {
        BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, 
        BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, 
        BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BFormTextarea, vSelect, BBreadcrumb, BFormFile
    },
    directives: {
        Ripple,
    },
    props: ['site', 'location'],
    data() {
        return {
            error_message        : null,
            showDismissibleAlert : false,

            label_position_list : ['Top Left', 'Top Center', 'Top Right', 'Bottom Left', 'Bottom Center', 'Bottom Right'],

            form: {
                site               : this.site,
                location           : this.location,
                promotional_video  : 'no',
                video              : {
                    name: '',
                    image: null
                },
                video_play         : 'service_time',
                idle_time          : '',
                enable_sound       : 'no',

                // new vars
                show_advertisement_info : 'no',
                info_label              : '',
                label_text_color        : '#ffffff',
                label_background_color  : '#ffffff',
                label_position          : ''
            }
        }
    },

    methods : {
        getDataFS(){
            return this.$store.dispatch(POST_API, {
                data:{
                    setting_name : 'promotion_settings',
                    location     : this.location
                    // site         : this.site
                },
                api: '/api/get-feedback-panel-setting-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    var data = this.$store.getters.getResults.data;

                    this.form.video= {
                        name: data.video == null ? '' : '',
                        image: data.video == null ? null : data.video+"?"+new Date()
                    };

                    this.form.promotional_video = data.promotional_video;
                    this.form.video_play    = data.video_play;
                    this.form.idle_time     = data.idle_time;
                    this.form.enable_sound  = data.enable_sound;
                    // new vars 
                    this.form.show_advertisement_info = data.show_advertisement_info;
                    this.form.info_label              = data.info_label;
                    this.form.label_text_color        = data.label_text_color;
                    this.form.label_background_color  = data.label_background_color;
                    this.form.label_position          = data.label_position;

                    // console.log(this.form);

                    
                }
            });
        },
        fileUpdate(event){
            var input = event.target;
            var files = event.target.files
            //console.log(files);
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var image = input.files[0];
                // console.log(image.type);

                if (image.type != 'application/mp4' && image.type != 'video/mp4') {
                     
                    Swal.fire({
                      position: 'center',
                      icon: 'warning',
                      title: 'Please upload mp4 video only',
                      showConfirmButton: false,
                      timer: 1500
                    })
                  
                    this.$refs['file-input'].reset()

                } else {

                    reader.onload = (e) => {
                        this.form.video.image = e.target.result;
                        this.form.video.name = image.name;
                    }
                }
            }
        },

        formSubmit(e){
            e.preventDefault();

            return this.$store.dispatch(POST_API, {
                data:{
                    site               : this.site,
                    location           : this.location,
                    promotional_video  : this.form.promotional_video,
                    video              : this.form.video,
                    video_play         : this.form.video_play,
                    idle_time          : this.form.idle_time,
                    enable_sound       : this.form.enable_sound,
                    // new vars 
                    show_advertisement_info : this.form.show_advertisement_info,
                    info_label              : this.form.info_label,
                    label_text_color        : this.form.label_text_color,
                    label_background_color  : this.form.label_background_color,
                    label_position          : this.form.label_position,
                },
                api: '/api/insert-video-settings-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;

                    this.successAlert();
                    
                }
            });        
        },
        resetData(){    
            this.form.video= {
                name: '',
                image: null
            };
            this.form.video_play    = 'service_time';
            this.form.idle_time     = '';
            this.form.enable_sound  = 'no';

            // new vars
            this.form.show_advertisement_info = 'no';
            this.resetAdInfo();
        },

        resetAdInfo(){    
            this.form.info_label             = '';
            this.form.label_text_color       = '#ffffff';
            this.form.label_background_color = '#ffffff';
            this.form.label_position         = '';
        }
    },

    mounted(){
        this.getDataFS();
    }, 

    watch: {
        location: function(){
            this.getDataFS();
        }
    }
}
</script>


<template>
<div>

    <b-row class="match-height pin_code_card_body">
        <b-col lg="12" md="12">
            <b-card>

                <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                    <div class="alert-body">
                        {{error_message}}
                    </div>
                </b-alert>
                
                <b-form @submit="formSubmit">

                    <div v-if="location">

                        
                    
                        <b-row>
                            <b-col md="6" >                        
                                <b-form-group label="Label Color" class="required">
                                    <input type="color" v-model="form.label_color" class="form-control">
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="12" >                        
                                <b-form-group label="Rate Our Location" class="">
                                    <b-form-input v-model="form.rate_our_location"/>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="12" >                        
                                <b-form-group label="" class="mt-1">
                                    <b-form-checkbox v-model="form.location_name_visibility" value="yes" unchecked-value="no" class="custom-control-success" switch>
                                        Location Name Visibility
                                    </b-form-checkbox>                                
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col class="mt-1">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="warning" class="mr-1" v-if="checkPermission($route.name, 'Update')">
                                    Update 
                                </b-button>
                            </b-col>
                        </b-row>

                    </div>
                    
                </b-form>

            </b-card>
        </b-col>
    </b-row>

</div>
</template>

<script>

import {
    BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BAlert,
    BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia,
    BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BFormTextarea, BBreadcrumb
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { GET_API, POST_API } from "../../../store/actions.type";
import vSelect from 'vue-select';

export default {
    components: {
        BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, 
        BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, 
        BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BFormTextarea, vSelect, BBreadcrumb
    },
    directives: {
        Ripple,
    },
    props: ['site', 'location'],
    data() {
        return {
            error_message        : null,
            showDismissibleAlert : false,

            form: {
                site               : this.site,
                location           : this.location,
                label_color        : '#ffffff',
                rate_our_location  : 'Rate our location',
                location_name_visibility: 'yes',
            }
        }
    },

    methods : {
        getDataLS(){
            return this.$store.dispatch(POST_API, {
                data:{
                    setting_name : 'label_settings',
                    location     : this.location
                    // site         : this.site
                },
                api: '/api/get-feedback-panel-setting-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    var data = this.$store.getters.getResults.data;

                    this.form.label_color       = data.label_color == undefined ? '#ffffff' : data.label_color;
                    this.form.rate_our_location = data.rate_our_location;
                    this.form.location_name_visibility = data.location_name_visibility;
                }
            });
        },

        formSubmit(e){
            e.preventDefault();

            return this.$store.dispatch(POST_API, {
                data:{
                    site               : this.site,
                    location           : this.location,
                    label_color        : this.form.label_color,
                    rate_our_location  : this.form.rate_our_location,
                    location_name_visibility : this.form.location_name_visibility,
                },
                api: '/api/insert-label-settings-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;

                    this.successAlert();                    
                }
            });        
        },

    },

    mounted(){
        this.getDataLS();
    }, 

    watch: {
        location: function(){
            this.getDataLS();
        }
    }
}
</script>


<template>
<div>

    <b-row class="match-height">
        <b-col lg="12" md="12">
            <b-card>

                <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                    <div class="alert-body">
                        {{error_message}}
                    </div>
                </b-alert>
                
                <b-form @submit="formSubmit">
                    
                    <div v-if="location">
                        
                        <b-row>
                            <b-col>
                                <b-table
                                    responsive
                                    show-empty
                                    ref = "refUserListTable"
                                    class = "position-relative mt-1 margin_top_zero_mobile"
                                    primary-key = "day"
                                    empty-text = "No records found"
                                    :fields = "tableColumns"
                                    :items = "form.data"
                                >
                                    <template #cell(start_time)="items">                            
                                        <flat-pickr v-model="items.item.start_time" placeholder="Select" class="form-control" :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}" />
                                    </template>

                                    <template #cell(end_time)="items">                            
                                        <flat-pickr v-model="items.item.end_time" placeholder="Select" class="form-control" :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}" />
                                    </template>

                                    <template #cell(reset)="items">                            
                                        <b-button variant="danger" @click="resetTime(items.item.day)">
                                            <feather-icon icon="XIcon"/>
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col class="mt-1">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="warning" class="mr-1" v-if="checkPermission($route.name, 'Update')">
                                    Update 
                                </b-button>
                            </b-col>
                        </b-row>

                    </div>
                    
                </b-form>

            </b-card>
        </b-col>
    </b-row>

</div>
</template>

<script>

import {
    BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BAlert,
    BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia,
    BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BFormTextarea, BBreadcrumb
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { GET_API, POST_API } from "../../../store/actions.type";
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';

export default {
    components: {
        flatPickr,BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, 
        BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, 
        BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BFormTextarea, vSelect, BBreadcrumb
    },
    directives: {
        Ripple,
    },
    props: ['site', 'location'],
    data() {
        return {
            error_message        : null,
            showDismissibleAlert : false,

            form: {
                site     : this.site,
                location : this.location,
                data     : []
            },

            // table data 
            tableColumns:[
                { key: 'day', label: 'Day', sortable: false , thStyle:  {width: '30%'}},
                { key: 'start_time', label: 'Start Time', sortable: false , thStyle:  {width: '32%'}},
                { key: 'end_time', label: 'End Time', sortable: false , thStyle:  {width: '32%'}},
                { key: 'reset', label: '', sortable: false , thStyle:  {width: '6%'}}
            ],
        }
    },

    methods : {
        resetTime(day){
            this.form.data.forEach((el, ind) => {
                if(el.day == day){
                    this.form.data[ind].start_time = null;
                    this.form.data[ind].end_time   = null;
                }
            });
        },

        getDataSTS(){
            return this.$store.dispatch(POST_API, {
                data:{
                    setting_name : 'service_time_settings',
                    location     : this.location
                    // site         : this.site
                },
                api: '/api/get-feedback-panel-setting-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    var data = this.$store.getters.getResults.data;

                    this.form.data    = data.data;
                }
            });
        },

        formSubmit(e){
            e.preventDefault();

            return this.$store.dispatch(POST_API, {
                data:{
                    site     : this.site,
                    location : this.location,
                    data     : this.form.data,
                },
                api: '/api/insert-service-time-settings-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;

                    this.successAlert();                    
                }
            });        
        },
    },

    mounted(){
        this.getDataSTS();
    }, 

    watch: {
        location: function(){
            this.getDataSTS();
        }
    }
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<template>
<div>
    
    <b-row class="match-height">
        <b-col lg="12" md="12">
            <b-card >

                <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                    <div class="alert-body">
                        {{error_message}}
                    </div>
                </b-alert>
                
                <b-form @submit="formSubmit">

                    <div v-if="form.location">

                        <b-row class="" ref="imgs">
                            <b-col md="4" cols="12" sm="6" v-for="(img, ind) in images" :key="ind" >
                                <b-card bg-variant="transparent" 
                                    text-variant="black" 
                                    class="overflow-hidden" 
                                    :border-variant="form.image != img._id ? 'secondary' : 'danger'"
                                    style="border-width: 10px !important"
                                    @click="updateSelect(ind)"
                                >
                                    <center>
                                        <img class="img-fluid" :src="img.image" variant="light-primary" style="height: 11rem; width: 100%;"/>
                                    </center> 
                                </b-card>                        
                            </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col class="mt-1">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="warning" class="mr-1" v-if="checkPermission($route.name, 'Update')">
                                    Update 
                                </b-button>
                            </b-col>
                        </b-row>

                    </div>
                    
                </b-form>

            </b-card>
        </b-col>
    </b-row>

</div>
</template>

<script>

import {
    BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BAlert,
    BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia,
    BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BFormTextarea, BBreadcrumb
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { GET_API, POST_API } from "../../../store/actions.type";
import vSelect from 'vue-select';

export default {
    components: {
        BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, 
        BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, 
        BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BFormTextarea, vSelect, BBreadcrumb
    },
    directives: {
        Ripple,
    },
    props: ['site', 'location'],
    data() {
        return {
            error_message        : null,
            showDismissibleAlert : false,

            images    : [],

            form: {
                site     : this.site,
                location : this.location,
                image    : '',
            }
        }
    },

    methods : {
        updateSelect(ind){
            this.form.image = this.images[ind]._id;
        },

        getBackgrounds(){
            return this.$store.dispatch(POST_API, {
                data:{
                    site     : this.site,
                    location : this.location
                },
                api: '/api/get-background-list'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.images = this.$store.getters.getResults.data;                
                }
            });
        },

        getData(){
            return this.$store.dispatch(POST_API, {
                data:{
                    setting_name : 'change_background',
                    location     : this.location
                    // site         : this.site
                },
                api: '/api/get-feedback-panel-setting-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    var data = this.$store.getters.getResults.data;

                    this.form.image = data.image;
                }
            });
        },

        formSubmit(e){
            e.preventDefault();

            return this.$store.dispatch(POST_API, {
                data:{
                    site     : this.site,
                    location : this.location,
                    image    : this.form.image,
                },
                api: '/api/change-background'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;

                    this.successAlert();
                    
                }
            });        
        },

    },

    mounted(){
        this.getBackgrounds().then(()=> {
            this.getData();
        })
    },

    watch: {
        location: function(){
            this.getBackgrounds().then(()=> {
                this.getData();
            })
        }
    }
}
</script>


<template>
<div>

    <b-row class="match-height pin_code_card_body">
        <b-col lg="12" md="12">
            <b-card>

                <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                    <div class="alert-body">
                        {{error_message}}
                    </div>
                </b-alert>
                
                <b-form @submit="formSubmit">

                    <div v-if="location">
                        
                        <b-row>
                            <b-col md="12" >                        
                                <b-form-group label="Footer Label" class="">
                                    <b-form-input v-model="form.footer_label"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col md="6" >                        
                                <b-form-group label="Footer Label Color" class="required">
                                    <input type="color" v-model="form.footer_label_color" class="form-control">
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="12" >                        
                                <b-form-group label="Footer Location" class="required">
                                    <b-form-radio v-model="form.footer_location" value="left" class="ml-2 mb-1">Left</b-form-radio>
                                    <b-form-radio v-model="form.footer_location" value="center" class="ml-2 mb-1">Center</b-form-radio>
                                    <b-form-radio v-model="form.footer_location" value="right" class="ml-2">Right</b-form-radio>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col class="mt-1">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="warning" class="mr-1" v-if="checkPermission($route.name, 'Update')">
                                    Update 
                                </b-button>
                            </b-col>
                        </b-row>

                    </div>
                    
                </b-form>

            </b-card>
        </b-col>
    </b-row>

</div>
</template>

<script>

import {
    BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BAlert,
    BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia,
    BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BFormTextarea, BBreadcrumb
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { GET_API, POST_API } from "../../../store/actions.type";
import vSelect from 'vue-select';

export default {
    components: {
        BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, 
        BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, 
        BAvatar, BTable, BModal, BFormRadio, BFormTimepicker, BFormTextarea, vSelect, BBreadcrumb
    },
    directives: {
        Ripple,
    },
    props: ['site', 'location'],
    data() {
        return {
            error_message        : null,
            showDismissibleAlert : false,

            form: {
                site               : this.site,
                location           : this.location,
                footer_label       : '',
                footer_label_color : '#ffffff',
                footer_location    : 'center'
            }
        }
    },

    methods : {
        getDataFS(){
            return this.$store.dispatch(POST_API, {
                data:{
                    setting_name : 'footer_settings',
                    location     : this.location
                    // site         : this.site
                },
                api: '/api/get-feedback-panel-setting-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    var data = this.$store.getters.getResults.data;

                    this.form.footer_label       = data.footer_label;
                    this.form.footer_label_color = data.footer_label_color == undefined ? '#ffffff' : data.footer_label_color;
                    this.form.footer_location    = data.footer_location;
                }
            });
        },

        formSubmit(e){
            e.preventDefault();

            return this.$store.dispatch(POST_API, {
                data:{
                    site               : this.site,
                    location           : this.location,
                    footer_label       : this.form.footer_label,
                    footer_label_color : this.form.footer_label_color,
                    footer_location    : this.form.footer_location
                },
                api: '/api/insert-footer-settings-data'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;

                    this.successAlert();
                    
                }
            });        
        },
    },

    mounted(){
        this.getDataFS();
    }, 

    watch: {
        location: function(){
            this.getDataFS();
        }
    }
}
</script>


<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb v-if="this.$route.params.site_id && this.$route.params.location_id" :items="breadCrumb()"/>
          <b-breadcrumb v-else :items="breadCrumb2()"/>
        </div>
      </div>
    </template>

    <div v-if="checkPermission($route.name, 'Update')">

        <!-- new implementation -->
        <b-card>
            <b-row>
                <b-col md="6" xs="12" >                        
                    <b-form-group label="Project Site" class="required">
                        <b-form-select v-model="site" @change="allLocations(); location = '';">
                            <b-form-select-option value="" disabled>Select Project Site</b-form-select-option>
                            <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name}}</b-form-select-option>
                        </b-form-select>                                
                    </b-form-group>
                </b-col>

                <!-- <b-col md="12" v-if="site">                         -->
                <b-col md="6" xs="12" v-if="site">                        
                    <b-form-group label="" class="margin_bottom_zero_mobile">
                        <div class="demo-inline-spacing margin_bottom_zero_mobile mt-1">
                            
                            <b-form-radio v-model="location_type" @change="updLocation(false)" value="select-location" class="inline-block">Select Location</b-form-radio>
                            <b-form-radio v-model="location_type" @change="updLocation(false)" value="all-location" class="inline-block">All Locations</b-form-radio>

                        </div>
                    </b-form-group>
                </b-col>

                <!-- <b-col md="12" v-if="location_type == 'select-location' && site"> -->
                <b-col md="12" v-if="location_type == 'select-location' && site">
                    <b-form-group label="Location" class="multiselect_muliple_options required margin_bottom_zero_mobile mobile_one_rem_top">
                        <v-select v-model="location" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :options="locations" placeholder="Select Location" multiple :close-on-select=false :clear-on-select=false >
                            <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching">
                                    No Location found for <em>{{ search }}</em>.
                                </template>
                                <em v-else style="opacity: 0.5">Type to Search Location</em>
                            </template>
                        </v-select>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>

        <b-card v-if="site && location.length > 0">
            <b-tabs pills justified class="mt-2 tab-desktop-view margin_top_zero_mobile table_button_tab table_button_tab2" v-if="feedbackList.length > 0">
                <b-tab :title="list.title" v-for="(list,ind) in feedbackList" :key="ind" lazy>
                    <legend class="legend_upper_border pt-1" style="width:130px">{{list.title}}</legend>

                    <div class="legen_inc_div">
                        <ChangeBackgroundComponent v-if="list.key == 'change_background'" :site="site" :location="location"/>
                        <ChangePincodeComponent v-if="list.key == 'change_pincode'" :site="site" :location="location"/>
                        <FooterSettingsComponent v-if="list.key == 'footer_settings'" :site="site" :location="location"/>
                        <HeaderSettingsComponent v-if="list.key == 'header_settings'" :site="site" :location="location"/>
                        <LabelSettingsComponent v-if="list.key == 'label_settings'" :site="site" :location="location"/>
                        <QrCodeSettingsComponent v-if="list.key == 'qr_code_settings'" :site="site" :location="location"/>
                        <ServiceTimeSettingsComponent v-if="list.key == 'service_time_settings'" :site="site" :location="location"/>
                        <VideoSettingsComponent v-if="list.key == 'video_settings'" :site="site" :location="location"/>

                    </div>

                </b-tab>

            </b-tabs>
        </b-card>
    
        <!-- <b-card v-else>
            <b-row class="match-height">
                <b-col md="12" class="text-center"> {{'Panel Settings Not Found.'}} </b-col>
            </b-row>
        </b-card> -->
        
    </div>
      
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBTooltip, BForm, BAlert,
  BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup,
  BFormFile, BFormGroup, BFormCheckbox, BCardBody, BBreadcrumb, BTabs, BTab, 
  BFormRadio
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import moment from "moment-timezone";
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue';
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';

import ChangeBackgroundComponent from './ChangeBackgroundComponent.vue';
import ChangePincodeComponent from './ChangePincodeComponent.vue';
import FooterSettingsComponent from './FooterSettingsComponent.vue';
import HeaderSettingsComponent from './HeaderSettingsComponent.vue';
import LabelSettingsComponent from './LabelSettingsComponent.vue';
import QrCodeSettingsComponent from './QrCodeSettingsComponent.vue';
import ServiceTimeSettingsComponent from './ServiceTimeSettingsComponent.vue';
import VideoSettingsComponent from './VideoSettingsComponent.vue';

export default {
    components: {
        BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, 
        BLink, BBadge, BDropdown, BDropdownItem, BPagination, BForm, vSelect, BAlert, 
        BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, 
        BFormFile, BFormGroup, BFormCheckbox, StatisticCardVertical, BBreadcrumb, 
        StatisticCardHorizontal, BTabs, BTab, ChangeBackgroundComponent, 
        ChangePincodeComponent, FooterSettingsComponent, HeaderSettingsComponent, 
        LabelSettingsComponent, QrCodeSettingsComponent, ServiceTimeSettingsComponent,
        BFormRadio,VideoSettingsComponent
    },

    data() {
        return {
            webUrl: process.env.VUE_APP_SERVER_URL,
            showDismissibleAlert:false,

            feedbackList : [],
            
            // site_name     : '',
            // location_name : '',

            sites     : [],
            locations : [],

            location_type : 'select-location',

            site     : this.$route.params.site_id ? this.$route.params.site_id : '',
            location : [],
        }
    },

    methods : {
        updLocation(flag = false){
            this.location = [];

            if(this.location_type == 'all-location'){
                // this.location = [{ _id:'all-project-site-location', name:'All Project Site Location'}];
                this.locations.forEach(el => {
                    this.location.push(el);
                });
            } 
            if(this.location_type == 'select-location'){
                if(this.$route.params.site_id && this.$route.params.location_id && flag == true){
                    this.locations.forEach(el => {
                        if(this.$route.params.location_id == el._id){
                            // this.location = el;
                            this.location.push(el);
                        }
                    });
                } else {
                    this.location = [];
                }
            }
        },
        
        allSites(){
            return this.$store.dispatch(POST_API, {
                data:{
                    role:this.$store.getters.currentUser.role,
                    om_sites:this.$store.getters.currentUser.om_sites,
                },
                api: '/api/all-sites'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data = this.$store.getters.getResults.data;

                    this.sites = data;

                    // if (this.sites.length == 1 ) {
                    //     this.site = this.sites[0]._id;
                    // }

                    this.site = this.defaultSite(this.sites)._id;

                    return this.sites;
                }
            });
        },

        allLocations(){
            return this.$store.dispatch(POST_API, { 
                data:{
                    site:this.site,
                    type:'toilet'
                },
                api: '/api/all-locations'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;
                    
                    this.locations = data;

                    return this.locations;
                }
            });
        },

        redirectRoute(route_name){
            this.$router.push({ name: route_name,params:{ site_id: this.$route.params.site_id, location_id: this.$route.params.location_id} });
        },

        getSettings(){
            return this.$store.dispatch(POST_API, {
                data:{type: 'web'},
                api: '/api/all-feedback-panel-settings'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                } else {
                    this.feedbackList = this.$store.getters.getResults.data;                    
                    return this.feedbackList;
                }
            });
        },

        breadCrumb(){
            var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
            }, {
                to: null,
                text: 'Project Sites'
            }, {
                to: {name:'job-sites'},
                text: 'Sites',
            }, {
                to: {name:'site-locations',params:{site_id: this.$route.params.site_id}},
                text: 'Locations',
            }, {
                to: null,
                text: 'Panel Settings',
                active: true
            }];
            return item;
        },
        
        breadCrumb2(){
            var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
            },{
                to:null,
                text: 'QR Feedback',
            },{
                to:null,
                text:'Panel Settings',
                active:true
            }];
            return item;
        }
    
    },

    mounted(){
        this.getSettings();
        this.allSites().then(()=>{
            this.allLocations().then(()=>{
                this.updLocation(true);
            })
        }); 

    }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
